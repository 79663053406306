import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { FiverrProDropdown } from './FiverrProDropdown';
import UpgradeToProLink from './UpgradeToProLink';

interface BusinessProSolutionsNavigationProps {
    isTransparent: boolean;
    onOpen: () => void;
}

export const BusinessProSolutionsNavigation: React.FC<BusinessProSolutionsNavigationProps> = ({
    isTransparent,
    onOpen,
}) => {
    const { userId } = getContext();

    return userId ? <UpgradeToProLink /> : <FiverrProDropdown isTransparent={isTransparent} onOpen={onOpen} />;
};
